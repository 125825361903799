.xlstable{
    overflow: auto;
    max-height: 350px;
.download-table-xls-button{
    background-color: #3880ff;
    position: absolute;
    right:30px;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 3px 6px;
    border: medium none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: left;    
    text-decoration: none;
    white-space: nowrap;
    border-radius: 3px;
    height: 30px;
    // &:after {
    //   content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Cloud Download</title><path d='M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>");
    //   float: right;
    //   padding-left: 1em;
    // }
}
h1{
    font-family: sans-serif;
  }
  
  table {
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
    text-shadow: 1px 1px 0px #fff;
    background: #eaebec;
    border: #ccc 1px solid;
    
  }
  table tr .topfixed{
    position: sticky;
    top: 0;
  }
  table th .topfixed{
    padding: 15px 35px;
    border-left:1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background: #ededed;
    position: sticky;
    top: 0;
  }
  table th {
    padding: 15px 35px;
    border-left:1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background: #ededed;
    // position: sticky;
    // top: 0;
  }
  

  table th:first-child{  
    border-left:none;  
  }
  
  table tr {
    text-align: center;
    padding-left: 20px;
  }
  
  table td:first-child {
    text-align: left;
    padding-left: 20px;
    border-left: 0;
  }
  
  table td {
    padding: 15px 35px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    background: #fafafa;
    background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#fafafa));
    background: -moz-linear-gradient(top, #fbfbfb, #fafafa);
  }
  
  table tr:last-child td {
    border-bottom: 0;
  }
  
  table tr:last-child td:first-child {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  
  table tr:last-child td:last-child {
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  
  table tr:hover td {
    background: #f2f2f2;
    background: -webkit-gradient(linear, left top, left bottom, from(#f2f2f2), to(#f0f0f0));
    background: -moz-linear-gradient(top, #f2f2f2, #f0f0f0);
  }

}