#login-page, #signup-page, #support-page {
  .login-logo {
    padding: 20px 0;
    min-height: 200px;
    text-align: center;
  }
  
  .login-logo img {
    max-width: 150px;
  }
  
  .list {
    margin-bottom: 0;
  }
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
  .loginform{
    padding: 20px;
    // border:10px solid #3880ff;
    // border-radius: 5px;
  }


}